import styled from '@emotion/styled';
import MaterialMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';

export interface IWaveDurationMenuProps {
    timerText: string;
    extensionOptions: number[];
    onExtensionSelected?: (extension: number) => void;
    isExtensionDisabled?: boolean;
    isSkipDisabled?: boolean;
    onSkipClicked?: () => void;
}

const AdjustmentButton = styled.button({
    position: 'relative',
    height: 48,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: '0 8px',
    margin: 0,
    border: 'none',
    textAlign: 'left',
    fontFamily: 'inherit',
    fontSize: 14,
    fontWeight: 600,
    cursor: 'pointer',
    backgroundColor: 'transparent',
    transition: 'background-color 0.15s',
    '&:hover': {
        backgroundColor: 'rgba(43, 45, 63, 0.05)',
    },
});

const AdjustmentIcon = styled.svg({
    opacity: 0.5,
    mixBlendMode: 'color-burn',
});

const AdjustmentLabel = styled.div({});

const AdjustmentEditIcon = styled.svg({
    position: 'absolute',
    right: 8,
    opacity: 0.5,
    mixBlendMode: 'color-burn',
});

const StyledMenu = styled(MaterialMenu)({
    ul: {
        minWidth: 180,
        padding: 0,
        margin: 0,
    },
    '& .MuiMenu-paper': {
        boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.1)',
    },
});

const MenuText = styled.div({
    fontSize: 14,
    fontWeight: 500,
});

const WaveDurationMenu = ({
    timerText,
    extensionOptions,
    onExtensionSelected,
    isExtensionDisabled,
}: IWaveDurationMenuProps): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleItemSelected = (extension: number): void => {
        onExtensionSelected && onExtensionSelected(extension);
        handleClose();
    };
    return (
        <>
            <AdjustmentButton disabled={isExtensionDisabled} onClick={handleOpen}>
                <AdjustmentIcon
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M11.55 0H3.45C3.02996 0 2.81994 0 2.65951 0.0817451C2.51839 0.15365 2.40365 0.268386 2.33175 0.409507C2.25 0.569941 2.25 0.779961 2.25 1.2V2.75589C2.25 3.12277 2.25 3.30622 2.29145 3.47885C2.32819 3.6319 2.3888 3.77822 2.47104 3.91243C2.5638 4.0638 2.69352 4.19352 2.95294 4.45294L5.15147 6.65147C5.44848 6.94848 5.59699 7.09699 5.65263 7.26824C5.70158 7.41887 5.70158 7.58113 5.65263 7.73176C5.59699 7.90301 5.44848 8.05151 5.15147 8.34853L2.95294 10.5471C2.69352 10.8065 2.5638 10.9362 2.47104 11.0876C2.3888 11.2218 2.32819 11.3681 2.29145 11.5212C2.25 11.6938 2.25 11.8772 2.25 12.2441V13.8C2.25 14.22 2.25 14.4301 2.33175 14.5905C2.40365 14.7316 2.51839 14.8463 2.65951 14.9183C2.81994 15 3.02996 15 3.45 15H11.55C11.97 15 12.1801 15 12.3405 14.9183C12.4816 14.8463 12.5963 14.7316 12.6683 14.5905C12.75 14.4301 12.75 14.22 12.75 13.8V12.2441C12.75 11.8772 12.75 11.6938 12.7086 11.5212C12.6718 11.3681 12.6112 11.2218 12.529 11.0876C12.4362 10.9362 12.3065 10.8065 12.0471 10.5471L9.84853 8.34853C9.55151 8.05151 9.40301 7.90301 9.34737 7.73176C9.29842 7.58113 9.29842 7.41887 9.34737 7.26824C9.40301 7.09699 9.55151 6.94848 9.84853 6.65147L12.0471 4.45294C12.3065 4.19352 12.4362 4.0638 12.529 3.91243C12.6112 3.77822 12.6718 3.6319 12.7086 3.47885C12.75 3.30622 12.75 3.12277 12.75 2.75589V1.2C12.75 0.779961 12.75 0.569941 12.6683 0.409507C12.5963 0.268386 12.4816 0.15365 12.3405 0.0817451C12.1801 0 11.97 0 11.55 0Z"
                        fill="url(#timerGrad)"
                    />
                    <defs>
                        <linearGradient id="timerGrad" x1="7.5" y1="0" x2="7.5" y2="15" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#2B2D3F" stopOpacity="0.2" />
                            <stop offset="1" stopColor="#2B2D3F" />
                        </linearGradient>
                    </defs>
                </AdjustmentIcon>
                <AdjustmentLabel>{timerText}</AdjustmentLabel>
                {onExtensionSelected && (
                    <AdjustmentEditIcon
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6 9L12 15L18 9"
                            stroke="#2B2D3F"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </AdjustmentEditIcon>
                )}
            </AdjustmentButton>
            <StyledMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                {extensionOptions.map((option) => (
                    <MenuItem key={option} onClick={() => handleItemSelected(option)}>
                        <MenuText>Hold current intensity and add {option} mins</MenuText>
                    </MenuItem>
                ))}
            </StyledMenu>
        </>
    );
};

export default WaveDurationMenu;
