import styled from '@emotion/styled';
import firebase from 'firebase/app';
import { findLastIndex } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import {
    BroadcastPersistentState,
    CoreEmotionalAtmosphere,
    isScheduledWavepath,
    Session,
    SessionRenderType,
    Wavepath,
} from 'wavepaths-shared/core';
import { getDurationFromScore } from 'wavepaths-shared/domain/sessionScore';
import { isWavepathActive } from 'wavepaths-shared/domain/wavepath';

import { GenericErrorBoundary } from '@/components/GenericErrorBoundary';
import { FeedbackButtons } from '@/components/notifications/FeedbackButtons';
import { GlobalSnackbarContext } from '@/components/Snackbar';
import { SessionCacheContext, useSessionCache } from '@/hooks/useAudioCache';
import { getCEAColour } from '@/util/ceaColours';

import LoadingOrb from '../../../common/components/LoadingOrb';
import configs from '../../../configs';
import { AudioPlayerContext, useAudioPlayer } from '../../sessions/EndedSessionsTable/useAudioPlayer';
import { DebugAudioEl } from '../../sessions/helpers';
import { WaveSelection } from '../autoGuide/waveSelection';
import { DepthStateVisualiser } from '../depthSlider/DepthStateVisualizer';
import { Clock } from '../SessionClock';
import { Timeline, TimelineContext } from '../timeline/Timeline';
import { VolumeMeter } from '../VolumeMeter';
import { CurrentWaveCardContainer } from './CurrentWaveCard';
import { PrecomposedGuideHeader } from './PrecomposedGuideHeader';
import { PregenEndOfSessionModal } from './PregenEndOfSessionModal';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* 20240613 IpadOS 17.5.1 breaking css changes caused overflow - forcing it to height seem to fix */
    height: 100vh;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto 1fr;
    justify-content: center;
`;

const Main = styled.main`
    display: grid;
    justify-content: stretch;
    align-content: stretch;
`;

const Content = styled.div`
    display: grid;
    grid-auto-flow: row;
    justify-content: center;
    grid-template-rows: 1fr min-content;
`;

const Center = styled.div`
    display: grid;
    place-content: center;
    justify-items: center;
`;

export const VolumeContainer = styled.div`
    background: rgba(255, 255, 255, 0.5);
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.4);
`;

export const PrecomposedAudioControls = styled.div`
    display: inline-grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 16px;
    padding: 25px;
    justify-content: center;
`;

export const PlayPauseButton = styled.button`
    display: grid;
    place-content: center;
    width: 80px;
    height: 80px;
    margin: 0;
    padding: 0;
    border-radius: 52px;
    border-width: 0;
    background-color: #2b2d3f;
    cursor: pointer;
    transition: background-color 0.15s ease;
    &:hover {
        background-color: #3d3f57;
    }
`;

export const WaveJumpButton = styled.button`
    display: grid;
    place-content: center;
    width: 64px;
    height: 64px;
    margin: 0;
    padding: 0px 4px 0px 0px;
    border-radius: 32px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    cursor: pointer;
    transition: background-color 0.15s ease;
    &:first-of-type {
        padding-right: 4px;
    }
    &:last-of-type {
        padding-left: 4px;
    }
    &:disabled {
        cursor: not-allowed;
    }
    &:disabled svg {
        opacity: 0.5;
    }
    &:hover {
        background-color: rgba(255, 255, 255, 0.9);
    }
    &:disabled:hover {
        background-color: rgba(255, 255, 255, 0.6);
    }
`;

export const AudioControlIconWhite = styled.svg`
    fill: white;
`;

export const AudioControlIconBlack = styled.svg`
    fill: black;
`;

const Bottom = styled.div({
    display: 'flex',
    justifySelf: 'center',
    flexDirection: 'column',
    minWidth: 280,
    width: '100%',
    maxWidth: 650,
    zIndex: 1,
    paddingBottom: 24,
});

const FeedbackButtonsPositioned = styled(FeedbackButtons)({
    position: 'absolute',
    bottom: 40,
    right: 40,
    display: 'flex',
    flexDirection: 'row',
});

const getCurrentWave = (wavepaths: Wavepath[], elapsedTimeMs: number): Wavepath | undefined => {
    return wavepaths.find(isWavepathActive(elapsedTimeMs));
};

const getCeaAtTime = (wavepaths: Wavepath[], elapsedTimeMs: number): CoreEmotionalAtmosphere | undefined => {
    // this should actually be calculated from the path score stages, not the top level "emotion"
    const activeWave = getCurrentWave(wavepaths, elapsedTimeMs);
    if (!activeWave) return;

    if ('emotion' in activeWave.pathScore) {
        if (typeof activeWave.pathScore.emotion === 'string') return activeWave.pathScore.emotion;
        if (!activeWave.pathScore.emotion) return;
        return activeWave.pathScore.emotion.from;
    }
};

const STATIC_WAVE_SELECTION: WaveSelection = {
    selection: 'none',
};

const STATIC_SET_WAVE_SELECTION = () => undefined;

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const SessionControls = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

export function PrecomposedGuide({
    session,
    firebaseUser,
    playDemoVO,
}: {
    session: Session;
    firebaseUser?: firebase.User;
    playDemoVO: boolean;
}): JSX.Element {
    //TODO initial state from persistent storage
    const [broadcastState] = useState<BroadcastPersistentState>(
        session.broadcastState || {
            timeline: [{ sessionId: session.id, dspOffset: 0, broadcastOffset: session.score.dspOffset }],
            discarded: [],
        },
    );
    const [elapsedTimeSecs, setElapsedTimeSecs] = useState(0);

    const audioPlayer = useAudioPlayer({
        broadcastIdentifier: session.broadcastIdentifier,
        errorContext: 'Pregen Session',
        mode: 'recording',
        broadcastState,
        broadcastElapsedTimeSecs: elapsedTimeSecs,
        voiceOverStages:
            session.renderType === SessionRenderType.PREDICTIVE_COMPOSED ? session.score.voiceover ?? [] : [],
        playDemoVO,
    });

    //TODO : Offline for Predictive sessions?
    const cachedStreamUrl = `${configs.freud.STREAM_BASE}/${session.broadcastIdentifier}/stream.m3u8`;
    const sessionCache = useSessionCache({
        audioCacheParams: {
            streamUrl: cachedStreamUrl,
            broadcastIdentifier: session.broadcastIdentifier,
            estimatedDurationSec: getDurationFromScore(session.score.wavepaths) / 1000,
        },
        session,
    });

    const { score, variableInputs: variables } = session;

    useInterval(() => {
        setElapsedTimeSecs(audioPlayer.currentTimeSecs);
    }, 1000);

    const { setSnackbarContent } = useContext(GlobalSnackbarContext);

    // TODO: This could be optimised a bit: we update the component every 100ms, but the scheduled wavepath timings don't change that often.
    const scheduledWavepaths = session.score.wavepaths.filter(isScheduledWavepath);
    const scheduledWavepathStartTimesSeconds = scheduledWavepaths.map(
        (p) => (p.plan?.fromTime ?? Number.MAX_SAFE_INTEGER) / 1000,
    );
    const currentWavepathIndex = findLastIndex(scheduledWavepathStartTimesSeconds, (s) => s <= elapsedTimeSecs);
    const isPossibleToGoToPrev = currentWavepathIndex > 0;
    const isPossibleToGoToNext = currentWavepathIndex < scheduledWavepathStartTimesSeconds.length;
    useEffect(() => {
        console.debug('Broadcast state', broadcastState);

        audioPlayer.actions.unblock();
    }, []);

    const togglePlaying = async () => {
        if (audioPlayer.playerStatus === 'playing') {
            audioPlayer.actions.pause({ reason: 'user' });
        } else {
            await audioPlayer.actions.unblock();
            audioPlayer.actions.play();
        }
    };

    const goToPrev = async () => {
        await audioPlayer.actions.unblock();
        if (currentWavepathIndex <= 0) return;
        const prevStartTime = scheduledWavepathStartTimesSeconds[currentWavepathIndex - 1];
        audioPlayer.actions.setTime(prevStartTime);
    };

    const goToNext = async () => {
        await audioPlayer.actions.unblock();
        if (currentWavepathIndex >= scheduledWavepathStartTimesSeconds.length - 1) {
            audioPlayer.actions.end();
        } else {
            const nextStartTime = scheduledWavepathStartTimesSeconds[currentWavepathIndex + 1];
            audioPlayer.actions.setTime(nextStartTime);
        }
    };

    const seek = async (wave: Wavepath, offsetSeconds?: number) => {
        await audioPlayer.actions.unblock();
        if (wave.plan?.fromTime !== undefined) {
            audioPlayer.actions.setTime(wave.plan?.fromTime / 1000 + (offsetSeconds ?? 0));
        }
    };

    const targetCea = getCeaAtTime(session.score.wavepaths, elapsedTimeSecs * 1000);
    const colorHex = targetCea ? getCEAColour(targetCea) : '#D3D3D3';
    const colorTransitionTimeInSecs = 30;

    useEffect(() => {
        setSnackbarContent(audioPlayer.warning);
    }, [audioPlayer.warning]);

    return (
        <Container>
            {session ? (
                <>
                    <AudioPlayerContext.Provider value={audioPlayer}>
                        <HeaderContainer>
                            <SessionCacheContext.Provider value={sessionCache}>
                                <PrecomposedGuideHeader session={session} />
                            </SessionCacheContext.Provider>
                        </HeaderContainer>

                        <GenericErrorBoundary>
                            <Main>
                                <Content>
                                    <Center>
                                        <TimelineContext.Provider
                                            value={{
                                                waveClick: seek,
                                            }}
                                        >
                                            <Timeline
                                                score={score}
                                                variables={variables}
                                                log={undefined}
                                                session={session}
                                                waveSelection={STATIC_WAVE_SELECTION}
                                                setWaveSelection={STATIC_SET_WAVE_SELECTION}
                                                elapsedTimeMs={elapsedTimeSecs * 1000}
                                                isScrollable
                                                phasesAlwaysVisible
                                            />
                                        </TimelineContext.Provider>
                                        <Clock
                                            timeElapsedMs={elapsedTimeSecs * 1000}
                                            totalDurationMs={Number(session.variableInputs.totalDuration) * 1000 * 60}
                                        />
                                        <PrecomposedAudioControls>
                                            {audioPlayer.playerStatus !== 'loading' ? (
                                                <>
                                                    <WaveJumpButton
                                                        aria-label="Jump to previous wave"
                                                        onClick={goToPrev}
                                                        disabled={!isPossibleToGoToPrev}
                                                    >
                                                        <AudioControlIconBlack
                                                            width="40"
                                                            height="40"
                                                            viewBox="0 0 40 40"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M9.16667 30V10H11.9444V30H9.16667ZM30.8333 30L16.2223 20L30.8333 10V30Z" />
                                                        </AudioControlIconBlack>
                                                    </WaveJumpButton>
                                                    <PlayPauseButton onClick={togglePlaying}>
                                                        {audioPlayer.playerStatus === 'playing' ? (
                                                            <AudioControlIconWhite
                                                                width="48"
                                                                height="48"
                                                                viewBox="0 0 48 48"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path d="M28.25 38V10H36V38H28.25ZM12 38V10H19.75V38H12Z" />
                                                            </AudioControlIconWhite>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {audioPlayer.playerStatus === 'idle' ||
                                                        audioPlayer.playerStatus === 'paused' ||
                                                        audioPlayer.playerStatus === 'error' ? (
                                                            <AudioControlIconWhite
                                                                width="48"
                                                                height="48"
                                                                viewBox="0 0 48 48"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path d="M14 38V10L36 24L14 38Z" />
                                                            </AudioControlIconWhite>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </PlayPauseButton>
                                                    <WaveJumpButton
                                                        aria-label="Jump to next wave"
                                                        onClick={goToNext}
                                                        disabled={!isPossibleToGoToNext}
                                                    >
                                                        <AudioControlIconBlack
                                                            width="40"
                                                            height="40"
                                                            viewBox="0 0 40 40"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M28.0556 30V10H30.8333V30H28.0556ZM9.16667 30V10L23.7778 20L9.16667 30Z" />
                                                        </AudioControlIconBlack>
                                                    </WaveJumpButton>
                                                </>
                                            ) : (
                                                <>Loading...</>
                                            )}
                                        </PrecomposedAudioControls>
                                        <SessionControls>
                                            {audioPlayer.isVolumeControllable ? (
                                                <VolumeContainer>
                                                    <VolumeMeter />
                                                </VolumeContainer>
                                            ) : (
                                                <></>
                                            )}
                                            <DebugAudioEl />
                                        </SessionControls>
                                    </Center>
                                    <Bottom>
                                        <CurrentWaveCardContainer
                                            currentWave={getCurrentWave(
                                                session.score.wavepaths,
                                                elapsedTimeSecs * 1000,
                                            )}
                                            elapsedTimeSecs={elapsedTimeSecs}
                                        />
                                    </Bottom>
                                    <FeedbackButtonsPositioned />
                                </Content>
                                <DepthStateVisualiser
                                    colorHex={colorHex}
                                    colorTransitionTimeInSecs={colorTransitionTimeInSecs}
                                />

                                {/* <Dialog
                                        open={audioPlayer.audioStatus === 'blocked'}
                                        onConfirm={audioPlayer.actions.unblock}
                                        message="In order to play sound through this tab, you first need to enable audio"
                                        confirmText="Enable Audio"
                                        title="Enable Audio"
                                        disableBackdropClick={true}
                                    /> */}
                            </Main>
                        </GenericErrorBoundary>
                    </AudioPlayerContext.Provider>
                </>
            ) : (
                <LoadingOrb />
            )}

            {firebaseUser && (
                <PregenEndOfSessionModal
                    currentUser={firebaseUser}
                    wavepaths={session.score.wavepaths}
                    elapsedTimeMs={elapsedTimeSecs * 1000}
                    sessionId={session.id}
                    sessionOwner={session.userId}
                />
            )}
        </Container>
    );
}
