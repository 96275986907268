import styled from '@emotion/styled';
import { isObject } from 'lodash';
import React, { useContext } from 'react';
import {
    CoreEmotionalAtmosphere,
    isPostludePathScore,
    isPreludePathScore,
    isScheduledPathScore,
    SessionState,
    TherapeuticDirection,
} from 'wavepaths-shared/core';
import { Wavepath } from 'wavepaths-shared/core';

import { useSessionRemoteTick } from '@/hooks';
import { getCEAColour } from '@/util/ceaColours';

import { SessionContext } from '../autoGuide/useSession';

const Container = styled.div<{ ceaColour: string }>(({ ceaColour }) => ({
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    borderRadius: 16,
    backgroundColor: ceaColour,
    overflow: 'hidden',
}));

const HeadingContainer = styled.div({
    height: 72,
    display: 'flex',
    padding: '0 24px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 12,
    alignSelf: 'stretch',
    flexShrink: 0,
});

const HeadingText = styled.div({
    fontFamily: 'inherit',
    fontSize: 20,
    fontWeight: 600,
    color: '#2B2D3F',
    textAlign: 'center',
});

const BodyContainer = styled.div({
    position: 'relative',
    flex: '1 1 80px',
    alignSelf: 'stretch',
    minHeight: 100,
});

const BodyContainerBg = styled.div({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(43, 45, 63, 0.30)',
    mixBlendMode: 'overlay',
});

const ProgressBar = styled.div<{ progress: number }>(({ progress }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: `${progress * 100}%`,
    height: '100%',
    backgroundColor: 'black',
    opacity: 0.8,
    mixBlendMode: 'overlay',
}));

const Heading = ({
    isPrelude,
    isPostlude,
    isPercussive,
    isStructured,
    wave,
    isLoading,
}: {
    isPostlude: boolean;
    isPrelude: boolean;
    isPercussive: boolean | null;
    isStructured: boolean | null;
    wave: Wavepath | null;
    isLoading: boolean;
}) => {
    if (isLoading) {
        return <>Loading...</>;
    } else if (isPrelude) {
        return <>Prelude Music</>;
    } else if (isPostlude) {
        return <>Postlude Music</>;
    } else if (!wave) {
        return <></>;
    } else {
        return (
            <>
                {isPercussive && 'Percussive'} {isStructured && 'Structured'} {wave.pathScore.direction}
            </>
        );
    }
};

const Adjustments = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    padding: 0 16px;
    @media (min-height: 720px) {
        padding: 8px 16px;
        min-height: 162px;
    }
`;

export default function NowAndNextCard({
    initialised,
    wave,
    heading,
    body,
    adjustments,
    progress,
}: {
    initialised: boolean;
    wave: Wavepath | null;
    heading: React.ReactElement;
    body: React.ReactElement;
    adjustments: React.ReactElement;
    progress: number;
}): React.ReactElement {
    const isLoading = !initialised;

    const tick = useSessionRemoteTick();
    const isPrelude = wave ? isPreludePathScore(wave.pathScore) : tick?.sessionState === SessionState.PRELUDE;
    const isPostlude = wave ? isPostludePathScore(wave.pathScore) : tick?.sessionState === SessionState.POSTLUDE;

    const pathScoreEmotion = wave && isScheduledPathScore(wave.pathScore) ? wave.pathScore.emotion : null;
    const cea =
        isPrelude || isPostlude
            ? CoreEmotionalAtmosphere.STILLNESS // Assume prelude and postlude are always stillness
            : pathScoreEmotion
            ? isObject(pathScoreEmotion)
                ? pathScoreEmotion.from // If a bridge wave, use the first CEA in it
                : pathScoreEmotion // If a scheduled wave, use the CEA of it
            : null;
    const isSilence = wave && wave.pathScore.direction === TherapeuticDirection.SILENCE;
    const isPercussive = wave && isScheduledPathScore(wave.pathScore) && wave.pathScore.mode === 'Percussive';
    const isStructured = wave && isScheduledPathScore(wave.pathScore) && wave.pathScore.mode === 'Structured';
    const ceaColour = cea
        ? getCEAColour(cea) // If there is a defined CEA, use its colour.
        : isSilence
        ? '#e6e4e1' // Specific colour for silence waves
        : isPercussive
        ? '#D3D8DA' // Specific colour for percussive waves without a CEA
        : 'rgba(43,45,63,0.5)'; // Default colour for all other waves (which should not ever happen really)

    const session = useContext(SessionContext);
    if (session === undefined) throw new Error('missing session context');

    return (
        <Container ceaColour={ceaColour}>
            <HeadingContainer>
                <HeadingText>
                    <Heading
                        isPostlude={isPostlude}
                        isPrelude={isPrelude}
                        isPercussive={isPercussive}
                        isStructured={isStructured}
                        wave={wave}
                        isLoading={isLoading}
                    />
                </HeadingText>
                {heading}
            </HeadingContainer>
            <BodyContainer>
                <BodyContainerBg />
                <ProgressBar progress={progress} />
                {body}
            </BodyContainer>
            <Adjustments>{adjustments}</Adjustments>
        </Container>
    );
}
