import styled from '@emotion/styled';
import { Card, Dialog, Input, Link, ListSubheader, MenuItem, Slider, Tab, Tabs } from '@material-ui/core';
import axios from 'axios';
import firebase from 'firebase';
import { isNil, uniq } from 'lodash';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import {
    clientOptionType,
    ClientVariablesMusicPreference,
    CollectionStatus,
    CoreEmotionalAtmosphere,
    ScoreLibrary,
    Session,
    SessionRenderType,
    SessionType,
    UserData,
    VoiceoverStage,
} from 'wavepaths-shared/core';
import { getMinMaxTotalDurationForWavepaths } from 'wavepaths-shared/domain/scores';
import { isAdmin } from 'wavepaths-shared/domain/user';
import { formatDurationMinutes, formatDurationSeconds } from 'wavepaths-shared/util/dateUtils';

import { Button, EvaIcon } from '@/component-library';
import { ContentDropdown } from '@/component-library/components/Control/Content';
import { SchedulingStyle } from '@/component-library/components/sessionOptions';
import TemplateDetail from '@/component-library/components/TemplateDetail';
import TypographyV2 from '@/component-library/typography/TypographyV2';
import { ErrorBox, SubscribeModal } from '@/components';
import WaveQueueEditor from '@/components/WaveQueueEditor';
import { Features } from '@/features';
import { useDebouncedState } from '@/hooks';
import { PreviewPlayerContext, usePreviewPlayer } from '@/hooks/usePreviewPlayer';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useScoreTemplate } from '@/hooks/useScoreTemplate';
import useSessionTemplates from '@/hooks/useSessionTemplates';
import { TestHooksContext } from '@/hooks/useTestHooks';

import * as audio from '../../audio';
import { useAuthContext } from '../../auth';
import {
    createUploadLinkForCustomVoiceOver,
    CustomVoiceover,
    getCustomVoiceOvers,
    ScoreTemplate,
} from '../../common/api/contentApi';
import { ISessionTemplate } from '../../common/api/savedTemplatesApi';
import * as sessionApi from '../../common/api/sessionApi';
import Snackbar, { GlobalSnackbarContext } from '../../common/components/Snackbar';
import useScoreLibrary from '../../common/hooks/useScoreLibrary';
import configs from '../../configs';
import { LayoutContainer } from '../../LayoutContainer';
import UserEvents from '../../UserEvents';
import { isDiscardingEdits, selectNoWave, WaveSelection } from '../inSession/autoGuide/waveSelection';
import { Timeline } from '../inSession/timeline/Timeline';
import { SessionPlannerTracking } from '../planner/sessionPlannerTracking';
import LoadingTemplateInfo from './LoadingTemplateInfo';
import useScorePlanner from './useScorePlanner';

export interface TemplateDetailParams {
    templateId: string;
}

export const SAVE_BEFORE_COLLAPSE_LABEL = 'Please Cancel or Save your changes before Collapsing this window';

type TemplateInfoCommonProps = {
    firebaseUser: firebase.User;
    userData: UserData;
    scoreLibrary: Pick<ScoreLibrary, 'pathScores' | 'presetScores'>;
};

type TemplateInfoWithSavedTemplateProps = TemplateInfoCommonProps & {
    savedTemplate: ISessionTemplate;
};

type TemplateInfoWithTemplateProps = TemplateInfoCommonProps & {
    template: ScoreTemplate;
};

type TemplateInfoContainerProps = TemplateInfoWithSavedTemplateProps | TemplateInfoWithTemplateProps;

function round5(x: number) {
    return Math.ceil(x / 5) * 5;
}

export const MISSING_SESSION_TEMPLATE_NAME_ERROR_LABEL = 'Please provide a name to save these settings as a template';
export const SAVE_TEMPLATE_ERROR_LABEL = 'There was an error saving data';
export const SESSION_CREATION_FAILURE_MESSAGE = 'Sorry, we were unable to create your session, please try again.';
export const SESSION_CREATION_TRAFFIC_TOO_HIGH_MESSAGE =
    'Sorry, we are experiencing an unusually high volume of sessions right now, please try again later.';

export function TemplateInfoContainer(props: TemplateInfoContainerProps) {
    const { scoreLibrary, firebaseUser } = props;
    const savedTemplate = 'savedTemplate' in props ? props.savedTemplate : undefined;

    const resource = 'template' in props ? props.template : props.savedTemplate;

    const query = useQueryParams();
    const isTour = query.has('isTour');
    const queryDuration = Number(query.get('duration'));
    const duration = isNaN(queryDuration) ? undefined : round5(queryDuration);
    const history = useHistory();

    const { isEnabled, isPersonal } = useAuthContext();

    useEffect(() => {
        if (isPersonal && !isEnabled(Features.FREE_ACCESS)) {
            history.push('/subscriptions');
        }
    }, [isPersonal]);

    const [waveSelection, setWaveSelection] = useState<WaveSelection>(selectNoWave());
    const [enableQuickFades] = useState(false);

    const [contentStatuses, setContentStatuses] = useState<'Approved' | 'Submitted' | 'All'>('Approved');

    const [isStartingSession, setStartingSession] = useState(false);

    const [_renderingType, setSessionRenderingType] = useLocalStorage<SessionRenderType>(
        'renderType',
        SessionRenderType.PREDICTIVE_COMPOSED,
    );
    const renderingType = _renderingType ?? SessionRenderType.PREDICTIVE_COMPOSED;

    const [snackbarContent, setSnackbarContent] = useState<string | null>(null);
    const closeSnackbar = useCallback(() => setSnackbarContent(null), []);

    const [upgradeModalIsOpen, setUpgradeModalIsOpen] = useState<boolean>(false);

    const [lastSchedulingType, setLastSchedulingType] = useLocalStorage<SchedulingStyle>('schedulingStyle', 'now');
    const [lastCanClientStartEarly, setLastCanClientStartEarly] = useLocalStorage<boolean>(
        'canClientStartEarly',
        savedTemplate && !isNil(savedTemplate.canClientStartEarly) ? savedTemplate.canClientStartEarly : true,
    );

    const initialAttributeInputs = {
        schedulingStyle: lastSchedulingType!,
        unlisted: savedTemplate && !isNil(savedTemplate.unlisted) ? savedTemplate.unlisted : true,
        canClientStartEarly: lastCanClientStartEarly!,
    };
    const [sessionAttributeInputs, setSessionAttributeInputs] = useState<{
        unlisted: boolean;
        schedulingStyle: SchedulingStyle;
        canClientStartEarly: boolean;
        scheduledStart?: number;
    }>(initialAttributeInputs);

    const variableInputs = duration ? { totalDuration: duration } : undefined;
    const defaults = 'template' in props ? { template: props.template, variableInputs } : props.savedTemplate;

    const {
        score,
        scoreTemplate,
        updatePathInScore,
        addPathToScore,
        movePathInScore,
        removePathFromScore,
        sessionVariableInputs,
        updateVariableInputs,
        upsertVoiceOverStage,
    } = useScorePlanner(scoreLibrary, defaults);

    const [minimumPathsDuration, maximumPathsDuration] = getMinMaxTotalDurationForWavepaths(score?.wavepaths);

    const {
        addTemplate,
        editTemplate,
        error: sessionTemplateError,
        deleteTemplate,
        mutationPending: sessionTemplateMutationPending,
    } = useSessionTemplates({ fbUser: firebaseUser });

    const setWaveSelectionOrNotify = (newSelection: WaveSelection, force = false) => {
        if (!force && isDiscardingEdits(newSelection, waveSelection)) {
            setSnackbarContent(SAVE_BEFORE_COLLAPSE_LABEL);
        } else {
            setWaveSelection(newSelection);
        }
    };

    const handleCreateSessionTemplate = async () => {
        if (!isEnabled(Features.SAVE_SESSION_TEMPLATES)) {
            setUpgradeModalIsOpen(true);
            return;
        }

        UserEvents.sessionTemplateCreated();
        setSnackbarContent(null);
        const sessionTemplateName = String(sessionVariableInputs.name);
        if (!sessionTemplateName || !sessionTemplateName.length) {
            setSnackbarContent(MISSING_SESSION_TEMPLATE_NAME_ERROR_LABEL);
            return;
        }
        if (!score) {
            setSnackbarContent('Duration cannot be empty');
            return;
        }
        const template = {
            name: sessionTemplateName,
            score,
            renderType: renderingType,
            variableInputs: sessionVariableInputs,
            canClientStartEarly: sessionAttributeInputs.canClientStartEarly,
        };

        const result = await addTemplate(template);
        if (result.ok) history.push('/');
    };

    const handleEditSessionTemplate = async () => {
        if (!isEnabled(Features.SAVE_SESSION_TEMPLATES)) {
            setUpgradeModalIsOpen(true);
            return;
        }

        UserEvents.sessionTemplateCreated();
        setSnackbarContent(null);
        const sessionTemplateName = String(sessionVariableInputs.name);
        if (!sessionTemplateName || !sessionTemplateName.length) {
            setSnackbarContent(MISSING_SESSION_TEMPLATE_NAME_ERROR_LABEL);
            return;
        }
        if (!score) {
            setSnackbarContent('Duration cannot be empty');
            return;
        }
        const template = {
            name: sessionTemplateName,
            score,
            renderType: renderingType,
            variableInputs: sessionVariableInputs,
            canClientStartEarly: sessionAttributeInputs.canClientStartEarly,
        };

        await editTemplate(template, resource.id);
        setSnackbarContent('Saved');
    };

    useEffect(() => {
        if (sessionTemplateError) {
            setSnackbarContent(SAVE_TEMPLATE_ERROR_LABEL);
        } else {
            setSnackbarContent(null);
        }
    }, [sessionTemplateError, setSnackbarContent]);

    const handleDeleteSessionTemplate = () => {
        UserEvents.sessionTemplateDeleted();
        deleteTemplate(resource.id);
        history.push('/templates/saved');
    };

    async function createSessionFromTemplate({ forPreview = false }) {
        const filteredLayerIds = window.location.search
            ? window.location.search
                  .substring(1)
                  .split(',')
                  .map((id) => +id)
            : [];
        const attributeInputs: Partial<Session> = {
            ...sessionAttributeInputs,
            //TODO: why this is duplicated with Sessions table?
            scheduledStart: getStartTime(sessionAttributeInputs.schedulingStyle, sessionAttributeInputs.scheduledStart),
        };

        const contentStatusesToInclude: CollectionStatus[] =
            contentStatuses === 'All' ? ['Approved', 'Submitted'] : [contentStatuses];

        const createdSession = await sessionApi.startSession(
            SessionType.ONE_ON_ONE,
            forPreview ? SessionRenderType.REAL_TIME : renderingType,
            forPreview ? { ...score, name: '[Preview] ' + score.name + ' ' + Date.now() } : score,
            sessionVariableInputs,
            attributeInputs,
            [],
            filteredLayerIds,
            firebaseUser,
            selectedClients,
            contentStatusesToInclude,
            //TODO: is this actually changing anything?
            forPreview ? true : enableQuickFades,
        );

        audio.audioCtx.resume();

        return { createdSession };
    }

    const handleSubmit = async () => {
        if (!score) return;

        setStartingSession(true);
        setSnackbarContent(null);

        // if (score.wavepaths.some((wp) => wp.pathScore.type === PathType.CURATED)) {
        //     setSnackbarContent(PLAYLIST_WARNING_MESSAGE);
        // }

        try {
            const { createdSession } = await createSessionFromTemplate({ forPreview: false });

            try {
                UserEvents.sessionCreated(createdSession, {
                    scheduleType: sessionAttributeInputs.schedulingStyle,
                    isTemplateCustom: !scoreTemplate,
                });
            } catch (e) {
                console.log(e);
            }

            if (sessionAttributeInputs.schedulingStyle == 'now') {
                if (
                    renderingType === SessionRenderType.REAL_TIME ||
                    renderingType === SessionRenderType.PREDICTIVE_COMPOSED
                ) {
                    history.push(`/session/${createdSession.id}${isTour ? '?isTour=true' : ''}`);
                } else if (renderingType === SessionRenderType.PRE_RENDERED) {
                    history.push(`/session/precomposed/${createdSession.id}`);
                } else {
                    throw new Error('Unknown session type');
                }
            } else {
                history.push(`/sessions`);
            }
        } catch (e: any) {
            console.error(e);
            if (e instanceof sessionApi.TooMuchTrafficError) {
                // setSnackbarContent(SESSION_CREATION_TRAFFIC_TOO_HIGH_MESSAGE);
                setStartingSession(false);
                return;
            }
            // setSnackbarContent(SESSION_CREATION_FAILURE_MESSAGE);
        }
        setStartingSession(false);
    };

    const previewPlayer = usePreviewPlayer();

    useEffect(() => {
        //mount
        (async () => {
            // hide feature for special users only
            if (!props.userData.groups?.includes('EditorPreviewMusic')) return;

            if (!previewPlayer.previewSession) {
                const { createdSession } = await createSessionFromTemplate({ forPreview: true });
                previewPlayer.setPreviewSession(createdSession);
            }
        })();
    }, []);

    useEffect(() => {
        if (waveSelection.selection == 'selected') {
            console.debug('New Selected wave', waveSelection);
            const updatedWave = score.wavepaths.find((x) => x.id == waveSelection.wave.id);
            updatedWave && previewPlayer.playWave(updatedWave);
        }
    }, [waveSelection, score]);

    const [selectedClients, setSelectedClients] = useState<clientOptionType[]>([]);

    return (
        <>
            <VoiceOverStagesContextWrapper>
                <PreviewPlayerContext.Provider value={previewPlayer}>
                    <TemplateDetail
                        id={resource.id}
                        emotionalities={
                            resource.emotionalities ?? {
                                primary: CoreEmotionalAtmosphere.SILENCE,
                                secondary: CoreEmotionalAtmosphere.SILENCE,
                                tertiary: CoreEmotionalAtmosphere.SILENCE,
                            }
                        }
                        title={resource.name}
                        subtitle={'subtitle' in resource ? resource.subtitle : ''}
                        description={'description' in resource ? resource.description : ''}
                        intensity={resource.intensity}
                        modality={resource.modality}
                        administration={'administration' in resource ? resource.administration : undefined}
                        dosage={'dosage' in resource ? resource.dosage : undefined}
                        renderType={renderingType}
                        onRenderTypeChange={setSessionRenderingType}
                        duration={Number(sessionVariableInputs.totalDuration)}
                        onDurationChange={(totalDuration: number) => updateVariableInputs({ totalDuration })}
                        onSubmit={handleSubmit}
                        onDelete={handleDeleteSessionTemplate}
                        submitDisabled={
                            isStartingSession || !sessionVariableInputs.totalDuration || sessionTemplateMutationPending
                        }
                        timelineComponent={
                            score ? (
                                <>
                                    <Timeline
                                        score={score}
                                        setWaveSelection={setWaveSelection}
                                        waveSelection={waveSelection}
                                        variables={sessionVariableInputs}
                                        isScrollable
                                        phasesAlwaysVisible={true}
                                        isPlanner
                                    />
                                </>
                            ) : (
                                <></>
                            )
                        }
                        tracklistComponent={
                            score && isEnabled(Features.WAVE_EDITOR) ? (
                                <AudioEditorTabs
                                    waveSelection={waveSelection}
                                    mainComponent={
                                        <WaveQueueEditor
                                            addPathAtIndex={addPathToScore}
                                            waveSelection={waveSelection}
                                            setWaveSelection={setWaveSelectionOrNotify}
                                            currentWaveIndex={0}
                                            movePathToIndex={movePathInScore}
                                            removePathAtIndex={removePathFromScore}
                                            setSnackbarContent={() => {
                                                console.log('mmm snacks');
                                            }}
                                            updatePathAtIndex={(index, partialWavepath) => {
                                                updatePathInScore(index, partialWavepath);
                                                //previewPlayer.playWave(...args);
                                            }}
                                            wavepaths={score.wavepaths}
                                            trackingHandlers={SessionPlannerTracking}
                                            shouldShow
                                        />
                                    }
                                    customComponent={
                                        <div style={{ marginTop: '10px' }}>
                                            <ListVoiceOverStages
                                                voiceOverStages={score.voiceover ?? []}
                                                onRemove={upsertVoiceOverStage}
                                                onAdd={upsertVoiceOverStage}
                                                onEdit={upsertVoiceOverStage}
                                                sessionDuration={Number(sessionVariableInputs.totalDuration) * 60}
                                            ></ListVoiceOverStages>
                                        </div>
                                    }
                                ></AudioEditorTabs>
                            ) : (
                                <></>
                            )
                        }
                        minDurationMins={scoreTemplate ? resource.durationMins.min : Math.round(minimumPathsDuration)}
                        maxDurationMins={scoreTemplate ? resource.durationMins.max : Math.round(maximumPathsDuration)}
                        onBackButtonClick={() => history.goBack()}
                        sessionName={sessionVariableInputs.name as string}
                        onSessionNameChange={(name) => updateVariableInputs({ name })}
                        musicalPreference={sessionVariableInputs.Acousticness as ClientVariablesMusicPreference}
                        onMusicalPreferenceChange={(Acousticness: number) => updateVariableInputs({ Acousticness })}
                        canSaveTemplates={isEnabled(Features.SAVE_SESSION_TEMPLATES)}
                        onCreateTemplate={handleCreateSessionTemplate}
                        onEditTemplate={handleEditSessionTemplate}
                        contentStatuses={contentStatuses}
                        onContentStatusChange={setContentStatuses}
                        allowedToEditDelete={isAdmin(props.userData) || firebaseUser.uid == savedTemplate?.creatorId}
                        showAdminFeatures={isAdmin(props.userData)}
                        schedulingType={sessionAttributeInputs.schedulingStyle}
                        onSchedulingTypeChange={(schedulingStyle: SchedulingStyle) => {
                            setSessionAttributeInputs({
                                ...sessionAttributeInputs,
                                schedulingStyle,
                                scheduledStart: schedulingStyle === 'specificTime' ? Date.now() : undefined,
                            });
                            setLastSchedulingType(schedulingStyle);
                        }}
                        canClientStartEarly={sessionAttributeInputs.canClientStartEarly}
                        onCanClientStartEarlyChange={(canClientStartEarly: boolean) => {
                            setSessionAttributeInputs({
                                ...sessionAttributeInputs,
                                canClientStartEarly,
                                scheduledStart: canClientStartEarly
                                    ? sessionAttributeInputs.scheduledStart
                                    : Date.now(),
                            });
                            setLastCanClientStartEarly(canClientStartEarly);
                        }}
                        scheduledStart={sessionAttributeInputs.scheduledStart}
                        onScheduledStartChange={(scheduledStart: number | undefined) =>
                            setSessionAttributeInputs({
                                ...sessionAttributeInputs,
                                scheduledStart,
                            })
                        }
                        selectedClients={selectedClients}
                        setSelectedClients={setSelectedClients}
                    />
                    <SubscribeModal isOpen={!!upgradeModalIsOpen} closeModal={() => setUpgradeModalIsOpen(false)} />
                    <Snackbar
                        type={'warning'}
                        isLongButton={false}
                        message={snackbarContent ?? ''}
                        confirmText={'OK'}
                        open={snackbarContent !== null}
                        closeSnackbar={closeSnackbar}
                    />
                </PreviewPlayerContext.Provider>
            </VoiceOverStagesContextWrapper>
        </>
    );
}

const useVoiceOversContextHook = () => {
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);
    const onUploaded = ({ custom_voiceover_id }: { custom_voiceover_id: string }) => {
        console.debug('Upload finished, notify other components', custom_voiceover_id);
        pullCustomVoiceovers();
    };
    const { firebaseUser } = useAuthContext();
    if (!firebaseUser) {
        throw new Error('No User');
    }

    const [customVoiceOvers, setCustomVoiceovers] = useState<CustomVoiceover[]>([]);
    const pullCustomVoiceovers = async () => {
        setCustomVoiceovers(await getCustomVoiceOvers(firebaseUser));
    };

    useEffect(() => {
        pullCustomVoiceovers();
    }, []);

    return {
        selectedIndex,
        setSelectedIndex,
        onUploaded,
        customVoiceOvers,
    };
};
export const VoiceOverStagesContext = createContext<ReturnType<typeof useVoiceOversContextHook> | undefined>(undefined);

export const VoiceOverStagesContextWrapper: React.FC<{ children: ReactNode | ReactNode[] }> = ({ children }) => {
    const contextValue = useVoiceOversContextHook();
    return (
        <>
            <VoiceOverStagesContext.Provider value={contextValue}>{children}</VoiceOverStagesContext.Provider>
        </>
    );
};

export function AudioEditorTabs({
    waveSelection,
    mainComponent,
    customComponent,
}: {
    waveSelection: WaveSelection;
    mainComponent: JSX.Element;
    customComponent: JSX.Element;
}) {
    const voiceOverStagesContext = useContext(VoiceOverStagesContext);
    if (!voiceOverStagesContext) {
        throw new Error('Missing context VoiceOverStagesContext');
    }
    const [currentTab, setCurrentTab] = useState<'main' | 'custom'>(
        voiceOverStagesContext.selectedIndex !== undefined ? 'custom' : 'main',
    );
    const handleChange = (_event: any, newValue: 'main' | 'custom') => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        if (voiceOverStagesContext.selectedIndex !== undefined) {
            setCurrentTab('custom');
        }
    }, [voiceOverStagesContext.selectedIndex]);

    useEffect(() => {
        if (waveSelection.selection === 'selected') {
            setCurrentTab('main');
        }
    }, [waveSelection]);

    return (
        <div
            style={{
                width: '100%',
            }}
        >
            <Tabs value={currentTab} onChange={handleChange}>
                <Tab value={'main'} label={'Music'} />
                <Tab value={'custom'} label={'Custom Sounds Channel 1'} />
            </Tabs>
            {currentTab == 'main' && mainComponent}
            {currentTab == 'custom' && customComponent}
        </div>
    );
}

const VoiceOversListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
`;

export function ListVoiceOverStages({
    voiceOverStages,
    onAdd,
    onEdit,
    onRemove,
    sessionDuration,
    elapsedTimeSecs,
}: {
    voiceOverStages: VoiceoverStage[];
    onAdd: ({ index, voiceOver }: { index?: number | undefined; voiceOver?: VoiceoverStage }) => void;
    onEdit: ({ index, voiceOver }: { index?: number | undefined; voiceOver?: VoiceoverStage }) => void;
    onRemove: ({ index, voiceOver }: { index?: number | undefined; voiceOver?: VoiceoverStage }) => void;
    sessionDuration: number;
    elapsedTimeSecs?: number;
}) {
    const voiceOverStagesContext = useContext(VoiceOverStagesContext);
    if (!voiceOverStagesContext) {
        throw new Error('No VoiceOverStagesContext');
    }
    const customVoiceOvers = voiceOverStagesContext.customVoiceOvers;
    const voiceOverStagesSorted = [...voiceOverStages].sort(
        (a, b) => (a.timing.from as number) - (b.timing.from as number),
    );

    return (
        <VoiceOversListContainer>
            {customVoiceOvers.length ? (
                voiceOverStagesSorted.map((item, displayIndex) => {
                    const index = voiceOverStages.indexOf(item);
                    return (
                        <>
                            {elapsedTimeSecs === undefined || elapsedTimeSecs < (item.timing.from as number) - 60 ? (
                                <StyledVOCard key={index}>
                                    <CustomVoiceoverForm
                                        customVoiceOvers={customVoiceOvers}
                                        sessionDuration={sessionDuration}
                                        onRemove={onRemove}
                                        onEdit={onEdit}
                                        initialValue={item}
                                        index={index}
                                        displayIndex={displayIndex}
                                        voiceOverStages={voiceOverStages}
                                        elapsedTimeSecs={elapsedTimeSecs}
                                    ></CustomVoiceoverForm>
                                </StyledVOCard>
                            ) : (
                                <></>
                            )}
                        </>
                    );
                })
            ) : (
                <div key="Loading">Loading...</div>
            )}
            <CustomVoiceoverForm
                key={'select' + voiceOverStages.length}
                customVoiceOvers={customVoiceOvers}
                sessionDuration={sessionDuration}
                onAdd={onAdd}
                voiceOverStages={voiceOverStages}
                elapsedTimeSecs={elapsedTimeSecs}
            ></CustomVoiceoverForm>
            <UploadCustomVoiceover
                key={'upload'}
                onUploaded={voiceOverStagesContext.onUploaded}
            ></UploadCustomVoiceover>
        </VoiceOversListContainer>
    );
}

const StyledVOCard = styled(Card)({
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyItems: 'center',
    padding: '15px',
    borderRadius: 4,
    background: 'rgba(255,255,255,1)',
    boxShadow: 'none',
    position: 'relative',
    zIndex: 'auto',
    overflow: 'visible',
    cursor: 'pointer',

    '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        zIndex: -1,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        borderRadius: 4,
        boxShadow: '0px 0px 20px rgba(0,0,0,0.1)',
    },
});

const PreviewAudio = styled.audio`
    &::-webkit-media-controls-volume-control-container {
        display: none;
    }
    height: 20px;
`;

export function CustomVoiceoverForm({
    sessionDuration,
    onAdd,
    onEdit,
    onRemove,
    initialValue,
    customVoiceOvers,
    voiceOverStages,
    index,
    displayIndex,
    elapsedTimeSecs,
    forceTimingTo,
}: {
    sessionDuration: number;
    onAdd?: ({ index, voiceOver }: { index?: number | undefined; voiceOver?: VoiceoverStage }) => void;
    onEdit?: ({ index, voiceOver }: { index?: number | undefined; voiceOver?: VoiceoverStage }) => void;
    onRemove?: ({ index, voiceOver }: { index?: number | undefined; voiceOver?: VoiceoverStage }) => void;
    initialValue?: VoiceoverStage;
    customVoiceOvers: CustomVoiceover[];
    voiceOverStages: VoiceoverStage[];
    index?: number;
    displayIndex?: number;
    elapsedTimeSecs?: number;
    forceTimingTo?: 'now';
}) {
    const MIN_FROM_AHEAD_SECS = 20;

    const stageInPreperation: VoiceoverStage | undefined = [...voiceOverStages]
        .sort((a, b) => (a.timing.from as number) - (b.timing.from as number))
        .find(
            (x) =>
                elapsedTimeSecs !== undefined &&
                (x.timing.from as number) - elapsedTimeSecs <= MIN_FROM_AHEAD_SECS &&
                (x.timing.from as number) - elapsedTimeSecs >= 0,
        );

    const stageToEdit = initialValue || stageInPreperation;

    const customVoiceOversVolumeMarks = [
        {
            value: 0,
            label: 'Silent',
        },
        {
            value: 0.25,
            label: 'Quiet',
        },
        {
            value: 0.5,
            label: 'Medium',
        },
        {
            value: 0.75,
            label: 'Loud',
        },
        {
            value: 1,
            label: 'Very Loud',
        },
    ];

    const customVoiceOversMusicGainMarks = [
        {
            value: 0,
            label: 'Music Off',
        },
        {
            value: 0.5,
            label: 'Reduced',
        },
        {
            value: 1,
            label: 'No Change',
        },
    ];

    const customVoiceOversFromMarks = [
        {
            value: 0,
            label: 'Beginning',
        },
        {
            value: sessionDuration,
            label: `${formatDurationMinutes(sessionDuration * 1000)}`,
        },
    ];

    for (let i = 1; i < Math.floor(sessionDuration / (60 * 60)); i++) {
        customVoiceOversFromMarks.push({
            value: i * 60 * 60,
            label: `${i}h`,
        });
    }

    const voiceOverStagesContext = useContext(VoiceOverStagesContext);
    if (!voiceOverStagesContext) {
        throw new Error('Missing context VoiceOverStagesContext');
    }

    useEffect(() => {
        const isSelected =
            voiceOverStagesContext.selectedIndex !== undefined &&
            index !== undefined &&
            voiceOverStagesContext.selectedIndex === index;
        if (!isOpen && isSelected) {
            setIsOpen(true);
        }
    }, [voiceOverStagesContext.selectedIndex, index]);

    const [selectedVoiceOver, _setSelectedVoiceOver] = useState<string | undefined>(stageToEdit?.custom_voiceover_id);
    const { isEnabled } = useAuthContext();
    const setSelectedVoiceOver = (custom_voiceover_id: string | undefined) => {
        //
        if (custom_voiceover_id === undefined) {
            _setSelectedVoiceOver(custom_voiceover_id);
            return;
        }
        const selectedCustomVoiceover = customVoiceOvers.find((x) => x.custom_voiceover_id === custom_voiceover_id);
        if (!selectedCustomVoiceover) throw new Error('Cant find selectedCustomVoiceover');
        if (!isEnabled(Features.UPLOAD_CUSTOM_SOUNDS) && !selectedCustomVoiceover.shared_public) {
            _setSelectedVoiceOver(undefined);
            setValidationError(
                "Your subscription doesn't include using custom sounds, please upgrade on your profile page",
            );
            return;
        }
        setValidationError(undefined);
        _setSelectedVoiceOver(custom_voiceover_id);
    };
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [volume, setVolume, immediateVolume] = useDebouncedState<number>(stageToEdit?.volume ?? 0.5);
    const [musicGain, setMusicGain, immediateMusicGain] = useDebouncedState<number>(stageToEdit?.musicGain ?? 1.0);
    //.sort mutates bastards
    const lastVoiceOver = [...voiceOverStages]
        .sort((a, b) => (a.timing.to as number) - (b.timing.to as number))
        .find(() => true);
    const minFrom = Math.max(
        lastVoiceOver ? (lastVoiceOver.timing.to as number) + 1 : 0,
        elapsedTimeSecs !== undefined ? elapsedTimeSecs + MIN_FROM_AHEAD_SECS : 0,
    );
    const [from, setFrom, immediateFrom, setFromForced] = useDebouncedState<number>(
        (stageToEdit?.timing.from as number) ?? minFrom,
    );
    const [fromPreset, setFromPreset] = useState<'begin' | 'now' | '2m' | '3m' | 'end' | 'custom' | undefined>(
        elapsedTimeSecs !== undefined && onAdd ? 'now' : onEdit ? 'custom' : undefined,
    );
    const alreadyStartedPlaying =
        elapsedTimeSecs !== undefined &&
        stageToEdit &&
        !stageInPreperation &&
        elapsedTimeSecs > (stageToEdit.timing.from as number);

    const remainingTimeSeconds =
        alreadyStartedPlaying && stageToEdit && elapsedTimeSecs !== undefined
            ? Math.max((stageToEdit.timing.to as number) - elapsedTimeSecs, 0)
            : 0;

    const [to, setTo] = useState<number>((stageToEdit?.timing.to as number) ?? 0);

    const selectionOptions = [...(customVoiceOvers || [])]
        .sort((a, b) => a.description.localeCompare(b.description))
        .sort((a, b) => (b.shared_public ? 1 : 0) - (a.shared_public ? 1 : 0))
        .map((item) => ({
            ...item,
            value: item.custom_voiceover_id,
            label: `${item.description} ${formatDurationSeconds((item.duration ?? 0) * 1000)}`,
            previewUrl: `${configs.freud.CUSTOM_VOICEOVERS_PREVIEW_BASE_URL}${item.filename}.mp3`,
        }));

    const selectedVoiceOverData = customVoiceOvers?.find((x) => x.custom_voiceover_id == selectedVoiceOver);

    const recalculateTo = () => {
        if (selectedVoiceOverData) {
            setTo(from + (selectedVoiceOverData.duration ?? 0));
        }
    };

    useEffect(() => {
        if (onAdd && from < minFrom + MIN_FROM_AHEAD_SECS && elapsedTimeSecs !== undefined) {
            setFromForced(minFrom + 2 * MIN_FROM_AHEAD_SECS);
        }
    }, [minFrom, onAdd, elapsedTimeSecs]);

    useEffect(() => {
        recalculateTo();
    }, [from, selectedVoiceOverData]);

    const [validationError, setValidationError] = useState<string | undefined>(undefined);

    useEffect(() => {
        let validationErrorCurrent = undefined;

        if (from > sessionDuration) {
            validationErrorCurrent = 'From time is beyond session duration, please correct';
        }

        //for very long voiceovers we want to allow for it to slip...
        // if (to > sessionDuration) {
        //     validationError.current = 'To time is beyond session duration, please correct timing';
        // }

        if (selectedVoiceOver) {
            const otherStages = voiceOverStages.filter((_x, index) => index !== index);
            otherStages.map((otherStage, otherIndex) => {
                if (otherStage.timing.from > from && otherStage.timing.from < to) {
                    validationErrorCurrent = `The beginning of other item (${
                        otherIndex + 1
                    }) overlaps with your setting, please correct`;
                } else if (otherStage.timing.to > from && otherStage.timing.to < to) {
                    validationErrorCurrent = `The ending of other item (${
                        otherIndex + 1
                    }) overlaps with your setting, please correct`;
                }
            });
        }

        setValidationError(validationErrorCurrent);

        if (
            onEdit &&
            index !== undefined &&
            selectedVoiceOver &&
            selectedVoiceOverData &&
            validationErrorCurrent === undefined &&
            stageToEdit &&
            (stageToEdit.custom_voiceover_id != selectedVoiceOver ||
                stageToEdit.timing.from != from ||
                stageToEdit.timing.to != to ||
                stageToEdit.volume != volume ||
                stageToEdit.musicGain != musicGain ||
                stageToEdit.duration != selectedVoiceOverData.duration)
        ) {
            console.debug('Editing Custom Voice Over', { from, to, volume, musicGain });
            onEdit({
                index,
                voiceOver: {
                    fileNameWithoutExtension: selectedVoiceOverData.filename,
                    custom_voiceover_id: selectedVoiceOverData.custom_voiceover_id,
                    description: selectedVoiceOverData.description,
                    duration: selectedVoiceOverData.duration ?? undefined, //TODO trim file if needed
                    volume: volume,
                    musicGain: musicGain,
                    timing: {
                        from: from,
                        to: to,
                    },
                    stage: `${selectedVoiceOver} ${from}`, // something unique
                },
            });
        }
    }, [from, to, volume, musicGain, selectedVoiceOver, selectedVoiceOverData, index]);

    const onAddButtonClick = () => {
        if (!selectedVoiceOverData) {
            throw new Error('No selected selectedVoiceOverData');
        }
        if (!onAdd) {
            throw new Error('no onAdd');
        }
        if (!selectedVoiceOverData.duration) {
            throw new Error('No duration');
        }
        let fromEffective: number;

        if (fromPreset !== undefined) {
            if (fromPreset == 'now' && elapsedTimeSecs !== undefined) {
                fromEffective = elapsedTimeSecs + MIN_FROM_AHEAD_SECS;
            } else if (fromPreset == '2m' && elapsedTimeSecs !== undefined) {
                fromEffective = elapsedTimeSecs + MIN_FROM_AHEAD_SECS + 2 * 60;
            } else if (fromPreset == '3m' && elapsedTimeSecs !== undefined) {
                fromEffective = elapsedTimeSecs + MIN_FROM_AHEAD_SECS + 3 * 60;
            } else if (fromPreset == 'end') {
                fromEffective = sessionDuration - selectedVoiceOverData.duration;
            } else {
                fromEffective = from;
            }
        } else {
            fromEffective = from;
        }
        const toEffective = fromEffective + selectedVoiceOverData.duration;
        onAdd({
            voiceOver: {
                fileNameWithoutExtension: selectedVoiceOverData.filename,
                custom_voiceover_id: selectedVoiceOverData.custom_voiceover_id,
                description: selectedVoiceOverData.description,
                duration: selectedVoiceOverData.duration, //TODO trim file if needed
                volume: volume,
                musicGain: musicGain,
                timing: {
                    from: fromEffective,
                    to: toEffective,
                },
                stage: `${selectedVoiceOver} ${fromEffective}`, // something unique
            },
        });
        setIsOpen(false);
    };

    const curatedCategories = uniq(
        selectionOptions.filter((x) => x.shared_public && x.category).map((x) => x.category),
    );

    return (
        <>
            <div style={{ width: '100%' }} key={index ?? 'new'} onClick={() => setIsOpen(true)}>
                {onAdd && !onEdit && !stageInPreperation ? (
                    <Button
                        variant="outlined"
                        style={{
                            width: 300,
                            height: 40,
                            borderRadius: 20,
                            border: '1px solid rgba(43,45,63, 0.2)',
                            fontSize: 16,
                            fontWeight: 600,
                        }}
                    >
                        <>
                            <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                                <svg
                                    width="25"
                                    height="25"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M16.7426 8.25738C19.0858 10.6005 19.0858 14.3995 16.7426 16.7427M8.25736 16.7426C5.91421 14.3995 5.91421 10.6005 8.25736 8.25735M5.42893 19.5711C1.52369 15.6658 1.52369 9.3342 5.42893 5.42896M19.5711 5.429C23.4763 9.33424 23.4763 15.6659 19.5711 19.5711M14.5 12.5C14.5 13.6046 13.6046 14.5 12.5 14.5C11.3954 14.5 10.5 13.6046 10.5 12.5C10.5 11.3955 11.3954 10.5 12.5 10.5C13.6046 10.5 14.5 11.3955 14.5 12.5Z"
                                        stroke="black"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <span>{forceTimingTo === 'now' ? 'Play a Sound now' : '+ Add'}</span>
                            </div>
                        </>
                    </Button>
                ) : (
                    <>
                        <div
                            style={{
                                display: 'grid',
                                gridAutoFlow: 'column',
                                gridTemplateColumns: '90% 10%',
                                width: '100%',
                            }}
                        >
                            <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '10px', alignItems: 'center' }}>
                                {alreadyStartedPlaying && remainingTimeSeconds ? (
                                    <>
                                        <div style={{ fontSize: 14, fontWeight: 600 }}>
                                            {formatDurationSeconds(remainingTimeSeconds * 1000)}
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                                {displayIndex !== undefined && (
                                    <span
                                        style={{
                                            border: '1px solid purple',
                                            borderRadius: '4px',
                                            minWidth: '20px',
                                            maxWidth: '50px',
                                            height: '20px',
                                            display: 'inline-block',
                                            verticalAlign: 'middle',
                                            textAlign: 'center',
                                            backgroundColor: 'transparent',
                                        }}
                                    >
                                        {displayIndex + 1}
                                    </span>
                                )}
                                <TypographyV2 size="text-md" truncated={true}>
                                    {selectedVoiceOverData?.description ?? ''}
                                </TypographyV2>
                                <EvaIcon name="volume-up-outline" size={16} fill="rgb(44, 57, 88)" />
                                {Math.round(volume * 100)}%
                                <EvaIcon name="music-outline" size={16} fill="rgb(44, 57, 88)" />
                                {Math.round(musicGain * 100)}%
                            </div>

                            <div>
                                {onRemove && index !== undefined ? (
                                    <Button
                                        variant="outlined"
                                        onClick={(evt) => {
                                            evt.stopPropagation();
                                            onRemove({
                                                index: index,
                                                voiceOver: undefined,
                                            });
                                            setIsOpen(false);
                                        }}
                                    >
                                        <EvaIcon name="trash-2-outline" size={16} fill="rgb(44, 57, 88)" />
                                    </Button>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>

            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <div
                    style={{
                        display: 'grid',
                        gridAutoFlow: 'row',
                        justifyContent: 'center',
                        gridTemplateColumns: 'min(350px, 100%)',
                        width: '100%',
                        padding: '25px',
                        gap: '25px',
                    }}
                >
                    <div style={{ width: 'min(300px, 100%)', display: 'grid', gridAutoFlow: 'row', gap: '10px' }}>
                        <TypographyV2>Choose file:</TypographyV2>
                        {!alreadyStartedPlaying ? (
                            <ContentDropdown
                                style={{ width: 'min(300px, 100%)' }}
                                colour={'dark'}
                                size={'large'}
                                value={selectedVoiceOver || ''}
                                onChange={(evt) => setSelectedVoiceOver(evt.target.value as string)}
                                disableUnderline={true}
                                renderValue={() => (
                                    <TypographyV2 size="text-md">
                                        {selectedVoiceOverData ? <>{selectedVoiceOverData.description}</> : <></>}
                                    </TypographyV2>
                                )}
                            >
                                <ListSubheader key={'yours'}>- Yours:</ListSubheader>
                                {selectionOptions
                                    .filter((x) => !x.shared_public)
                                    .map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            <VoiceOverMenuItem item={item} />
                                        </MenuItem>
                                    ))}
                                {curatedCategories.map((category) => [
                                    <ListSubheader key={'category-' + category}>
                                        - {category} curated by Wavepaths:
                                    </ListSubheader>,

                                    selectionOptions
                                        .filter((x) => x.shared_public && x.category === category)
                                        .map((item) => (
                                            <MenuItem key={item.value} value={item.value}>
                                                <VoiceOverMenuItem item={item} />
                                            </MenuItem>
                                        )),
                                ])}
                            </ContentDropdown>
                        ) : (
                            <>{selectedVoiceOverData ? <>{selectedVoiceOverData.description}</> : <></>}</>
                        )}
                    </div>

                    {onAdd || onEdit ? (
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '10px',
                                alignItems: 'center',
                            }}
                        >
                            <>
                                <span>Timing:</span>
                                {onAdd ? (
                                    <>
                                        {elapsedTimeSecs !== undefined ? (
                                            <>
                                                <Button
                                                    variant={fromPreset == 'now' ? 'solid-blue' : 'outlined'}
                                                    onClick={() => setFromPreset('now')}
                                                >
                                                    In 20s
                                                </Button>
                                                <Button
                                                    variant={fromPreset == '2m' ? 'solid-blue' : 'outlined'}
                                                    onClick={() => setFromPreset('2m')}
                                                >
                                                    In 2min
                                                </Button>
                                                <Button
                                                    variant={fromPreset == '3m' ? 'solid-blue' : 'outlined'}
                                                    onClick={() => setFromPreset('3m')}
                                                >
                                                    In 3min
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button
                                                    variant={fromPreset == 'begin' ? 'solid-blue' : 'outlined'}
                                                    onClick={() => setFromPreset('begin')}
                                                >
                                                    Beginning
                                                </Button>
                                            </>
                                        )}
                                        <Button
                                            variant={fromPreset == 'end' ? 'solid-blue' : 'outlined'}
                                            onClick={() => setFromPreset('end')}
                                        >
                                            Before Ending
                                        </Button>
                                    </>
                                ) : (
                                    <></>
                                )}
                                <Button
                                    variant={fromPreset == 'custom' ? 'solid-blue' : 'outlined'}
                                    onClick={() => setFromPreset('custom')}
                                >
                                    Custom
                                </Button>
                            </>
                        </div>
                    ) : (
                        <></>
                    )}
                    {fromPreset == 'custom' ? (
                        <div style={{ width: '100%', display: 'grid' }}>
                            <div>From : {formatDurationSeconds(from * 1000)}</div>
                            {!alreadyStartedPlaying ? (
                                <div style={{ padding: '5px 30px' }}>
                                    <Slider
                                        aria-label="From"
                                        defaultValue={immediateFrom}
                                        step={1}
                                        min={0}
                                        max={sessionDuration}
                                        valueLabelDisplay="off"
                                        marks={customVoiceOversFromMarks}
                                        onChange={(_event, value) => {
                                            setFrom(value as number);
                                        }}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    ) : (
                        <></>
                    )}
                    {selectedVoiceOverData?.duration ? (
                        <div style={{ width: '100%' }}>
                            <>Duration: {formatDurationSeconds(selectedVoiceOverData.duration * 1000)}</>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div
                        style={{
                            width: '100%',
                            display: 'grid',
                            minWidth: '300px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                            }}
                        >
                            <EvaIcon name="volume-up-outline" size={16} fill="rgb(44, 57, 88)" />{' '}
                            <TypographyV2 size="text-md" truncated={true}>
                                Volume: {Math.round(volume * 100.0)}%
                            </TypographyV2>
                        </div>
                        <div style={{ padding: '5px 30px' }}>
                            <Slider
                                aria-label="Volume"
                                defaultValue={immediateVolume}
                                step={0.01}
                                min={0}
                                max={1}
                                valueLabelDisplay="off"
                                marks={customVoiceOversVolumeMarks}
                                onChange={(_event, value) => {
                                    setVolume(value as number);
                                }}
                            />
                        </div>
                        {alreadyStartedPlaying ? (
                            <i>
                                <small>Change will be audible within {MIN_FROM_AHEAD_SECS} seconds</small>
                            </i>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'grid',
                            minWidth: '300px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                            }}
                        >
                            <EvaIcon name="music-outline" size={16} fill="rgb(44, 57, 88)" />{' '}
                            <TypographyV2 size="text-md" truncated={true}>
                                Music Gain: {Math.round(musicGain * 100.0)}%
                            </TypographyV2>
                        </div>
                        <div style={{ padding: '5px 30px' }}>
                            <Slider
                                aria-label="Music Gain"
                                defaultValue={immediateMusicGain}
                                step={0.01}
                                min={0}
                                max={1}
                                valueLabelDisplay="off"
                                marks={customVoiceOversMusicGainMarks}
                                onChange={(_event, value) => {
                                    setMusicGain(value as number);
                                }}
                            />
                        </div>
                        {alreadyStartedPlaying ? (
                            <i>
                                <small>Change will be audible within {MIN_FROM_AHEAD_SECS} seconds</small>
                            </i>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div style={{ width: '100%' }}>
                        {validationError !== undefined ? (
                            <>
                                <ErrorBox message={validationError} />
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div style={{ display: 'flex', gap: '25px' }}>
                        {onAdd ? (
                            isEnabled(Features.LIVE_SESSION_RENDERING) ? (
                                <Button variant="outlined" onClick={onAddButtonClick}>
                                    Save
                                </Button>
                            ) : (
                                <Link href="/profile" target="_blank">
                                    Upgrade Subscription to Add
                                </Link>
                            )
                        ) : (
                            <></>
                        )}
                        {onRemove && index !== undefined ? (
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    onRemove({
                                        index: index,
                                        voiceOver: undefined,
                                    });
                                    setIsOpen(false);
                                }}
                            >
                                Remove
                            </Button>
                        ) : (
                            <></>
                        )}
                        <Button variant="clear-underlined" onClick={() => setIsOpen(false)}>
                            Close
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    );

    function VoiceOverMenuItem({ item }: { item: { value: string; label: string; previewUrl: string } }) {
        return (
            <div
                style={{
                    display: 'grid',
                    gridAutoFlow: 'column',
                    gap: '10px',
                    alignItems: 'center',
                }}
            >
                {elapsedTimeSecs !== undefined ? (
                    <></>
                ) : (
                    <PreviewAudio
                        src={item.previewUrl}
                        controls={true}
                        preload="none"
                        onClick={(evt) => {
                            //stop propagation for Safari Ipad clicks bubbling
                            evt.stopPropagation();
                        }}
                    />
                )}
                <TypographyV2 size="text-md">{item.label}</TypographyV2>
            </div>
        );
    }
}

function UploadCustomVoiceover({
    onUploaded,
}: {
    onUploaded?: ({ custom_voiceover_id }: { custom_voiceover_id: string }) => void;
}) {
    const fileRef = useRef<HTMLInputElement>();
    const { firebaseUser } = useAuthContext();
    const [description, setDescription] = useState<string>('');
    const [duration, setDuration] = useState<number>(0);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isAnalyzing, setIsAnalyzing] = useState<boolean>(false);
    const testHooks = useContext(TestHooksContext);

    const MAX_FILE_SIZE = testHooks?.maxFileSize ?? 1024 * 1024 * 1024; //1gb
    const globalSnackBar = useContext(GlobalSnackbarContext);

    const readFileMetadata = async () => {
        try {
            setIsAnalyzing(true);
            console.debug('readFileMetadata', fileRef.current);

            if (!fileRef.current) {
                throw new Error('no file');
            }
            if (!fileRef.current.files || !fileRef.current.files.length) {
                return;
            }

            if (!description) {
                setDescription(fileRef.current.files[0].name.replace('.mp3', ''));
            }
            //TODO analysis of the file
            setDuration(0);

            const durationAnalysed = await new Promise<number>((res, reject) => {
                if (!fileRef.current || !fileRef.current.files || !fileRef.current.files[0]) {
                    reject('No file');
                    return;
                }

                const file = fileRef.current.files[0];
                if (file.size > MAX_FILE_SIZE) {
                    reject('File too large, maximum size 1GB');
                    return;
                }

                const reader = new FileReader();
                const audio = document.createElement('audio');
                reader.onload = function (e: any) {
                    audio.src = e.target.result;
                    audio.addEventListener(
                        'durationchange',
                        function () {
                            res(audio.duration);
                        },
                        false,
                    );

                    audio.addEventListener(
                        'onerror',
                        function () {
                            reject('Error reading file');
                        },
                        false,
                    );
                };
                reader.readAsDataURL(file);
            });

            setDuration(Math.ceil(durationAnalysed));
        } catch (err: any) {
            globalSnackBar.setSnackbarContent(`Upload Error : ${err.toString()}`);
        } finally {
            setIsAnalyzing(false);
        }
    };

    const uploadFile = async () => {
        if (!firebaseUser) {
            throw new Error('No user');
        }
        if (!fileRef.current?.files?.length) {
            throw new Error('No File');
        }

        setIsUploading(true);
        try {
            const createUploadLinkResponse = await createUploadLinkForCustomVoiceOver(firebaseUser, {
                description,
                duration,
            });

            if (!createUploadLinkResponse.URI || !createUploadLinkResponse.custom_voiceover_id) {
                console.debug('Error creating upload URL');
                return;
            }

            const uploadResponse = await axios.post(
                createUploadLinkResponse.URI,
                await fileRef.current.files[0].arrayBuffer(),
            );

            if (uploadResponse.status !== 200) {
                console.debug('Upload response fail', uploadResponse);
                setIsUploading(false);
            } else {
                globalSnackBar.setSnackbarContent('Upload completed, you can use now your file');
                fileRef.current.value = '';
                setDescription('');
                setDuration(0);
                onUploaded && onUploaded({ custom_voiceover_id: createUploadLinkResponse.custom_voiceover_id });
                setIsOpen(false);
            }
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <>
            <div key="upload" style={{ width: '100%' }}>
                {!isOpen ? (
                    <Button variant="outlined" onClick={() => setIsOpen(true)}>
                        Upload new file
                    </Button>
                ) : (
                    <></>
                )}
            </div>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <div style={{ padding: '15px', display: 'grid', gridAutoFlow: 'row', gap: '10px' }}>
                    <div style={{ width: '100%' }}>
                        Choose MP3 File{' '}
                        <input
                            type="file"
                            accept=".mp3"
                            ref={(x) => {
                                fileRef.current = x ?? undefined;
                            }}
                            onChange={readFileMetadata}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        Description{' '}
                        <Input
                            style={{ width: '100%' }}
                            type="text"
                            placeholder=""
                            value={description}
                            onChange={(event) => setDescription(event.target.value)}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        Duration (detected){' '}
                        <Input type="text" value={duration ? formatDurationSeconds(duration * 1000) : '-'} disabled />
                    </div>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        {isUploading ? <div>Uploading...</div> : <></>}
                        {isAnalyzing ? <div>Analyzing file...</div> : <></>}
                        {!isUploading && !isAnalyzing && duration ? (
                            <>
                                <Button variant="outlined" onClick={uploadFile}>
                                    Upload
                                </Button>
                            </>
                        ) : (
                            <></>
                        )}
                        <Button variant="clear-underlined" onClick={() => setIsOpen(false)}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

function TemplateInfoFetchContainer() {
    const { templateId } = useParams<TemplateDetailParams>();

    const { firebaseUser, userData } = useAuthContext();
    const scorelibrary = useScoreLibrary(firebaseUser);

    const { template } = useScoreTemplate({ fbUser: firebaseUser, id: templateId });

    if (!template || scorelibrary.loading || !firebaseUser || !userData) return <LoadingTemplateInfo />;

    return (
        <TemplateInfoContainer
            firebaseUser={firebaseUser}
            userData={userData}
            scoreLibrary={scorelibrary}
            template={template}
        />
    );
}

function TemplateDetailWithNav() {
    return (
        <LayoutContainer>
            <TemplateInfoFetchContainer />
        </LayoutContainer>
    );
}

function CopySessionFetchContainer() {
    const { firebaseUser, userData } = useAuthContext();
    const scorelibrary = useScoreLibrary(firebaseUser);

    const history = useHistory<{ sessionTemplate?: ISessionTemplate }>();
    const sessionTemplate = history?.location?.state?.sessionTemplate;

    if (!sessionTemplate || scorelibrary.loading || !firebaseUser || !userData) return <LoadingTemplateInfo />;

    return (
        <TemplateInfoContainer
            firebaseUser={firebaseUser}
            userData={userData}
            scoreLibrary={scorelibrary}
            savedTemplate={sessionTemplate}
        />
    );
}

export function CopySessionContainer() {
    return (
        <LayoutContainer>
            <CopySessionFetchContainer />
        </LayoutContainer>
    );
}

function getStartTime(schedulingStyle: SchedulingStyle, scheduledStart: number | undefined) {
    //MK20231031 In the past scheduledStart was coming from the Browser but its not reliable, so we pass 1 as 'now'
    // we correct it here so all the legacy code can still use the absolute timestamp
    // ideally this should come from same server that stamps end time
    if (schedulingStyle === 'now') {
        return 1;
    }
    if (schedulingStyle === 'later') {
        return undefined;
    }
    if (!scheduledStart) {
        throw new Error('scheduledStart is undefined but schedulingStyle is specificTime');
    }
    return new Date(scheduledStart).getTime();
}

export default TemplateDetailWithNav;
