import styled from '@emotion/styled';
import { isArray } from 'lodash';
import React from 'react';
import { Session, SessionScoreDosage } from 'wavepaths-shared/core';

import { CustomTypographyProps } from '@/component-library/typography/Typography';

import { DOSAGE_LABELS, MEDICINE_LABELS } from '../../common/domain/modalitiesOld';

interface SessionNameProps {
    session: Session;
    variant?: CustomTypographyProps['variant'];
}

const Container = styled.div<{ variant: CustomTypographyProps['variant'] }>`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
`;

export const SessionName: React.FC<SessionNameProps> = ({ session, variant = 'subtitle2' }) => (
    <Container variant={variant}>{getSessionName(session)}</Container>
);

export function getSessionName(session: Session): string | undefined {
    const renderedName = (session.variableInputs?.name as string | undefined) || session.score.name;
    if (renderedName?.length > 0) {
        return renderedName;
    } else {
        const medicine = getMedicineLabels(session);
        const dosage =
            session.variableInputs.dosage && DOSAGE_LABELS[session.variableInputs.dosage as SessionScoreDosage];
        const dur = session.variableInputs.totalDuration;
        let name = medicine;
        if (dosage) name += ` | ${dosage}`;
        if (dur) name += ` | ${dur} mins`;
        return name;
    }
}

function getMedicineLabels(session: Session) {
    if (session.score.selectionCriteria?.medicine) {
        const medicines = isArray(session.score.selectionCriteria.medicine)
            ? session.score.selectionCriteria.medicine
            : [session.score.selectionCriteria.medicine];
        return medicines.map((m) => MEDICINE_LABELS[m]).join(', ');
    }
}
