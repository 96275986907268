import styled from '@emotion/styled';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useContext } from 'react';
import { Wavepath } from 'wavepaths-shared/core';

import { Button, EvaIcon, Typography } from '@/component-library';

import { RemoteSessionControlsContext } from '../../../pages/inSession/Guide';
import WavePathSelector from '../WavePathSelector';
import { SKIP_WAVE_LABEL } from './WaveCard';
import { WaveCardHeaderContentContainer } from './WaveCardHeaderContentContainer';

const ExpandedContentContainer = styled.div({
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
});

export const WaveCardDialog = ({
    isOpen,
    wave,
    waveIndex,
    previousWave,
    onUpdateWave,
    onRemoveWave,
    onSkipToWave,
    onClose,
}: {
    isOpen: boolean;
    wave: Wavepath;
    waveIndex: number;
    previousWave?: Wavepath;
    onUpdateWave: (wave: Wavepath) => void;
    onRemoveWave: (e: any) => void;
    onSkipToWave?: (e: any) => void;
    onClose: () => void;
}) => {
    const remoteContext = useContext(RemoteSessionControlsContext);
    const { pathScore } = wave;
    const { name } = pathScore;
    return (
        <Dialog open={isOpen}>
            <DialogTitle>Edit wave</DialogTitle>
            <DialogContent>
                <>
                    <WaveCardHeaderContentContainer
                        name={name}
                        wave={wave}
                        previousWave={previousWave}
                        isOpen={isOpen}
                        pathScore={pathScore}
                        onRemoveWave={onRemoveWave}
                    />
                    <ExpandedContentContainer>
                        {onSkipToWave ? (
                            <Button style={{ marginBottom: 16 }} size="s" onClick={onSkipToWave} variant="outlined">
                                <EvaIcon name="skip-forward" size={16} />
                                <Typography variant="body2">{SKIP_WAVE_LABEL}</Typography>
                            </Button>
                        ) : null}
                        <WavePathSelector wavepath={wave} onUpdateWave={onUpdateWave} />
                    </ExpandedContentContainer>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        {remoteContext &&
                            remoteContext.queuedFunction &&
                            remoteContext.queuedFunction.waveIndex === waveIndex &&
                            remoteContext.queuedFunction.showQueuingUIInline && (
                                <>
                                    <Button
                                        variant="solid-blue"
                                        onClick={() => {
                                            remoteContext.onSkipQueue();
                                            onClose();
                                        }}
                                    >
                                        Apply now or autosave in ...{' '}
                                        {remoteContext.queuedFunction.timeUntilExecutionInSeconds}s
                                    </Button>
                                    <Button
                                        variant="clear-underlined"
                                        onClick={() => {
                                            remoteContext.onCancelQueuedFunction();
                                        }}
                                    >
                                        Cancel edit
                                    </Button>
                                </>
                            )}
                        <Button variant="clear-underlined" onClick={onClose}>
                            Close
                        </Button>
                    </div>
                </>
            </DialogContent>
        </Dialog>
    );
};
