import styled from '@emotion/styled';
import React from 'react';

const Container = styled.div<{
    width: number;
    phasesVisible?: boolean;
    position: 'top' | 'bottom';
}>(
    ({ width, phasesVisible, position }) => `
    transform:  translateY(${position === 'top' ? '-25px' : '63px'});
    width: ${width}px;
    height: 20px;
    position: relative;
    display: inline-grid;
    align-items: center;
    pointer-events: none;
    opacity: ${phasesVisible ? 1 : 0};
    transition: opacity 0.3s ease;
`,
);

const Label = styled.div<{
    state: 'current' | 'past' | 'future';
    isPlanner?: boolean;
}>(
    ({ state, isPlanner }) => `
    position: absolute;
    top: 0;
    left: 4px;
    display: inline-grid;
    height: 20px;
    border: 1px solid ${isPlanner ? '#6980B4' : state === 'current' ? '#2c3958' : 'transparent'};
    border-radius: 10px;
    align-items: center;
    padding: 0 8px 1px 8px;
    background-color: ${isPlanner ? 'rgba(255, 255, 255, 0.9)' : '#2B2D3F'}; 
    white-space: nowrap;
    text-align: center;
    font-family: inherit;
    font-size: 11.1px;
    font-weight: 600;
    color:  ${isPlanner ? '#6980B4' : '#ffffff'};
    user-select: none;
`,
);

const Marker = styled.div<{
    position: 'top' | 'bottom';
}>(
    ({ position }) => `
    display: inline-grid;
    align-items: ${position === 'top' ? 'start' : 'end'};
    width: 5px;
    height: 15px;
    overflow: hidden;
    position: absolute;
    top: ${position === 'top' ? '9px' : 'auto'};
    bottom: ${position === 'bottom' ? '9px' : 'auto'};
    `,
);

const SVG = styled.svg`
    display: block;
    width: 100%;
    height: auto;
`;

const Path = styled.path<{
    state: 'current' | 'past' | 'future';
    isPlanner?: boolean;
}>(
    ({ state, isPlanner }) => `
    stroke-width: 1.5;
    stroke: ${state === 'current' ? '#2c3958' : isPlanner ? '#6980B4' : '#2B2D3F'};
`,
);

interface TimelinePhaseProps {
    phase: {
        startTime: number;
        duration: number;
        phase: string;
    };
    width: number;
    phasesVisible?: boolean;
    position: 'top' | 'bottom';
    state: 'current' | 'past' | 'future';
    isPlanner?: boolean;
}

export const TimelinePhase: React.FC<TimelinePhaseProps> = ({
    phase,
    width,
    phasesVisible,
    position,
    state,
    isPlanner,
}) => {
    return (
        <Container width={width} phasesVisible={phasesVisible} position={position}>
            <Label state={state} isPlanner={isPlanner}>
                {phase.phase}
            </Label>
            <Marker position={position}>
                <SVG width="5" height="59" viewBox="0 0 5 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                    {position === 'top' ? (
                        <Path
                            state={state}
                            isPlanner={isPlanner}
                            d="M4.5 1.06437C3.33333 0.897708 1 1.36437 1 4.56437C1 7.76437 1 45.5631 1 58.5625"
                        />
                    ) : (
                        <Path
                            state={state}
                            isPlanner={isPlanner}
                            d="M4 57.5333C2.83333 57.6999 0.5 57.2333 0.5 54.0333C0.5 50.8333 0.5 13.0345 0.5 0.0351562"
                        />
                    )}
                </SVG>
            </Marker>
        </Container>
    );
};
